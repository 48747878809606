.home {
  --default-section-padding: 4rem;
  --box-shadow: 0px 0px 30px -10px rgb(0 0 0 / 90%);
}

.section {
  padding: var(--section-padding, var(--default-section-padding)) 0;
  position: relative;
}

.dark {
  background: var(--pattern-bg);
}

.centered-text {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  max-width: 500px;
}

.verification-info-item {
  text-align: left;
}

.image-bg {
  height: 100%;
  left: 0;
  position: absolute;
  pointer-events: none;
  top: 0;
  width: 100%;
  z-index: -1;
}

.hero {
  --nav-color: #000;
  --hamburger-color: #fff;

  background-size: cover;
  min-height: 500px;
  padding: 2rem 0 8rem 0;
  position: relative;
}

.hero-content {
  --text-color: #fff;

  align-items: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 3rem;
  position: relative;
  text-align: center;
  z-index: 2;
}

.hero-content-text {
  font-size: 1.2rem;
  max-width: 660px;
}

.hero-header {
  --menu-wrapper-color: #fff;

  align-items: center;
  display: flex;
  justify-content: space-between;
}

.hero-logo-wrapper {
  max-width: 150px;
  position: relative;
  width: 40%;
  z-index: 10;
}

.hero-content-title {
  margin: 2rem 0;
  color: #fff;
}

.hero-starting-at {
  margin-top: 1rem;
}

.hero-button {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 100%;
  z-index: 2;
}

.hero-menu-wrapper {
  --navbar-height: 125px;

  display: flex;
  justify-content: flex-end;
}

.signin-link {
  --btn-font-size: 0.9rem;
  --link-color: #fff;
}

.sash-image {
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 150px;
  z-index: 1;
}

.intro-subtitle {
  text-transform: uppercase;
}

.intro-video {
  background: #000;
  box-shadow: var(--box-shadow);
  margin: 0 auto;
  position: relative;
  width: auto;
  max-width: 90%;
}

.intro-video::before {
  display: block;
  padding-top: calc(100% / (var(--aspect-ratio, 16 / 9)));
  content: '';
}

.intro-video > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.intro-play-icon {
  height: 50px;
  width: 50px;
}

.intro-title {
  padding: 3rem 0 0 0;
  text-align: center;
}

.intro-text {
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
}

.build-image > * {
  box-shadow: var(--box-shadow);
}

.build-content {
  margin-top: 2rem;
}

.showcase {
  padding: 0;
  margin-top: var(--default-section-padding);
}

.showcase .section-wrapper {
  transform: translateY(-4rem);
}

.earn .section-wrapper {
  justify-content: center;
  text-align: center;
}

.earn-images {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.earn-image {
  width: 80vw;
  margin-bottom: 2rem;
  max-width: 400px;
}

.verification {
  margin-top: var(--default-section-padding);
}

.verification-text {
  font-size: 1.2rem;
  text-align: center;
}

.verification-info-list {
  list-style-type: none;
  margin: 1rem 0 0 0;
  padding: 0;
}

.endorsed-logos {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  margin-top: 2rem;
}

.endorsed-logo {
  flex: 0 0 calc(50% - 1.5rem);
  width: calc(50% - 1.5rem);
}

.accordion-cta {
  margin-top: 1rem;
}

.created-by {
  background: var(--primary);
  color: #fff;
  padding: 4rem 0;
  text-align: center;
}

.become-content {
  margin: 0 0 2rem 0;
}

.become .section-wrapper {
  justify-content: center;
  text-align: center;
}

@media (min-width: 768px) and (min-height: 425px) {
  .build .section-wrapper {
    align-items: center;
    justify-content: space-between;
    display: flex;
  }

  .build-image {
    flex: 0 0 50%;
    width: 50%;
  }

  .build-content {
    flex: 0 0 45%;
    width: 45%;
    margin: 0;
  }

  .showcase {
    background: linear-gradient(
      180deg,
      var(--bg-color) 0%,
      var(--bg-color) calc(0% + var(--default-section-padding)),
      var(--pattern-bg) calc(0% + var(--default-section-padding)),
      var(--pattern-bg) calc(100% - var(--default-section-padding)),
      var(--bg-color) calc(100% - var(--default-section-padding)),
      var(--bg-color) 100%
    );
    margin-bottom: calc(var(--default-section-padding) * -1);
    margin-top: 0;
    padding: 0;
  }

  .showcase .section-wrapper {
    align-items: center;
    justify-content: space-between;
    display: flex;
    transform: none;
  }

  .showcase-image {
    flex: 0 0 50%;
    order: 2;
    width: 50%;
  }

  .showcase-content {
    flex: 0 0 45%;
    width: 45%;
  }

  .earn-images {
    justify-content: center;
    flex-direction: row;
    gap: 0 2rem;
  }

  .endorsed-logo {
    flex: 0 0 calc(25% - 1.5rem);
    width: calc(25% - 1.5rem);
  }

  .verification-text {
    max-width: 740px;
    margin: 0 auto;
    font-size: 1.4rem;
    line-height: 1.2;
  }

  .verification-info {
    display: flex;
    margin-top: 2rem;
    gap: 3rem;
    position: relative;
  }

  .verification-info-image {
    flex: 0 0 calc(50% - 1.5rem);
    width: calc(50% - 1.5rem);
  }

  .verification-info-content {
    flex: 0 0 calc(50% - 1.5rem);
    width: calc(50% - 1.5rem);
  }

  .faqs-wrapper {
    max-width: 650px;
    margin: 0 auto;
  }

  .created-by-content {
    font-size: 1.2rem;
  }

  .hero {
    --nav-color: #fff;
    --account-nav-color: #fff;
    --link-color: #fff;
  }
}

@media (min-width: 992px) {
  .home {
    --default-section-padding: 6rem;
  }

  .hero {
    padding: 2rem 0 10rem 0;
  }

  .hero-logo-wrapper {
    max-width: 150px;
  }

  .intro .section-wrapper {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  .intro-video {
    flex: 0 0 50%;
    margin: 0;
    order: 2;
    width: 50%;
  }

  .intro-title {
    flex: 0 0 42%;
    padding: 0;
    width: 42%;
  }

  .endorsed {
    padding: 4rem 0;
  }

  .show-them-screenshot-content {
    padding: 0 0 0 2rem;
  }

  .show-them-screenshot-phone {
    flex: 0 0 60%;
  }

  .show-them-screenshot-phone-wrapper-mobile {
    display: none;
  }

  .show-them-screenshot-phone-wrapper {
    display: block;
  }

  .show-them-screenshot-content {
    flex: 0 0 40%;
  }

  .show-them-screenshot-build {
    display: flex;
    position: relative;
    z-index: 3;
  }

  .show-them-screenshot-build .show-them-screenshot-phone-wrapper {
    height: 700px;
    margin-bottom: -340px;
    transform: translate(10px, -50px);
  }

  .show-them-screenshot-earn {
    /* position: relative; */
    /* z-index: 2; */
  }

  .show-them-screenshot-earn .show-them-screenshot-phone {
    height: 770px;
    margin-bottom: -400px;
  }

  .show-them-screenshot-earn .show-them-screenshot-phone-wrapper {
    transform: translate(35px, -150px);
  }

  .show-them-screenshot-showcase .show-them-screenshot-phone {
    flex: 0 0 100%;
    height: 520px;
  }

  .show-them-screenshot-showcase .show-them-screenshot-content {
    position: absolute;
    right: 0;
    top: 0;
    width: 40%;
  }

  .show-them-screenshot-earn {
    flex-direction: row;
    display: flex;
    justify-content: flex-end;
  }

  .show-them-screenshot-earn .show-them-screenshot-phone {
    display: flex;
    justify-content: flex-end;
  }

  .show-them-screenshot-showcase {
    margin-bottom: 0;
  }

  .show-them-screenshot-showcase .show-them-screenshot-phone {
    margin-bottom: -50px;
  }

  .show-them-screenshot-showcase .show-them-screenshot-phone-wrapper {
    transform: translate(10px, -20px);
  }

  .show-them-bg {
    /* height: 700px; */
  }

  .verification {
    padding-bottom: 0;
  }

  .verification-info {
    margin-top: 4rem;
  }

  .verification-info-item {
    margin-bottom: 2.6rem;
  }

  .verification-info-item-text {
    font-size: 1.3rem;
    line-height: 1.25;
  }

  .quotes-section {
    padding: 2rem 0 4rem;
  }
}

@media (min-width: 1200px) {
  .hero-logo-wrapper {
    max-width: 200px;
  }

  .hero-content-text {
    font-size: 1.4rem;
    max-width: 880px;
    line-height: 1.35;
  }

  .sash-image {
    width: 240px;
  }

  .intro-title .h1-6 {
    line-height: 1.35;
  }

  .endorsed {
    padding: 5rem 0;
  }

  .endorsed-logos {
    margin-top: 3.5rem;
  }

  .show-them-screenshot-build .show-them-screenshot-phone {
    height: 775px;
    margin-bottom: -460px;
  }

  .show-them-screenshot-build .show-them-screenshot-phone-wrapper {
    transform: translate(40px, -10px);
  }

  .show-them-screenshot-earn .show-them-screenshot-phone {
    height: 850px;
    margin-bottom: -450px;
  }

  .show-them-screenshot-earn .show-them-screenshot-phone-wrapper {
    transform: translate(40px, -160px);
  }

  .show-them-screenshot-showcase .show-them-screenshot-phone {
    height: 650px;
  }

  .show-them-screenshot-showcase .show-them-screenshot-phone-wrapper {
    transform: translate(0px, -25px);
  }

  .certificates {
    padding: 6rem 0;
  }

  .certificates-levels {
    justify-content: center;
  }

  .certificates-level {
    flex: 0 0 275px;
    margin: 0 3rem;
  }

  .certificates-level-image {
    padding: 0;
  }

  .certificates-level-badge {
    bottom: -30px;
    left: -60px;
    width: 120px;
  }

  .certificates-level-cta {
    margin-top: 4rem;
  }

  .verification-info {
    margin-top: 5rem;
  }

  .verification-info-content {
    margin-top: 1rem;
  }
}

@media (min-width: 1600px) {
  .home {
    --default-section-padding: 8rem;
  }

  .hero-logo-wrapper {
    max-width: 260px;
  }
}
